import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Slideover from './Slideover';
import Routing from './Routing';
import Dropdown from './Dropdown';
import { v4 as uuidv4 } from 'uuid';

import { SlideoverProvider, useSlideoverDispatch } from './SlideoverContext.js';
import Autocomplete from './Autocomplete.js';


var autoComplete = require('js-autocomplete/auto-complete.min.js');

global.autoComplete = autoComplete;


export default function App(props) {

	const dispatch 		= useSlideoverDispatch();

	/*

	const placeholders 	= document.getElementsByClassName("ui-btnx");
	const drops 		= document.getElementsByClassName("ui-dropdown");

	const dropdowns = Array.from(drops, (el) => createPortal(
	    	<Dropdown {...(el.dataset)} />,
	    	el,
	    	uuidv4()
	    )  
	);

	const buttons = Array.from(placeholders, (el) => createPortal(
	    	<div onClick={() => dispatch({type: 'opened', url: '/app/ugykezelo/sheet/form/new'})}>btn</div>,
	    	el,
	    	uuidv4()
	    )  
	);

	*/

	return (
		<>
			<Slideover />
		</>
	)
}