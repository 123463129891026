import React, { createContext, useContext, useCallback, useEffect, useReducer } from 'react';

const SlideoverContext = createContext(null);
const SlideoverDispatchContext = createContext(null);

export function SlideoverProvider({ children }) {
    const [slideover, dispatch] = useReducer(
        slideoverReducer,
        initialSlideover
    );


    async function loadUrl(url) {

        const response  = await fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        
        return await response.text();


    }

    const customDispatch = useCallback(async (action) => {
        switch (action.type) {
            case "opened": {

                const data = fetch(action.url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                .then(response => {

                    return response.text();

                })
                .then(text => {
                    
                    dispatch({

                        type: 'loaded',
                        content: text

                    })
                
                });

                break;
            }
            default:
                // Not a special case, dispatch the action
                dispatch(action);
            }
    }, []); // The empty array causes this callback to only be created once per component instance


    return (
        <SlideoverContext.Provider value={slideover}>
            <SlideoverDispatchContext.Provider value={customDispatch}>
                {children}
            </SlideoverDispatchContext.Provider>
        </SlideoverContext.Provider>
    );
}

export function useSlideover() {
    return useContext(SlideoverContext);
}

export function useSlideoverDispatch() {
    return useContext(SlideoverDispatchContext);
}


function slideoverReducer(slideover, action) {

    switch (action.type) {
        case 'loaded':

            return {

                isOpen: true,
                label: 'label',
                description: 'desc',
                url: action.url,
                content: action.content


            }

            break;
        case 'closed':

            return {
                isOpen: false,
                label: 'label',
                description: 'desc',
                url: null,
                content: 'closed'

            };

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}


const initialSlideover = {

    isOpen: false,
    label: 'label',
    description: 'desc',
    url: null,
    content: 'hello content'

}