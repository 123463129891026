import React from 'react';
import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Routing from './Routing';


import { useSlideoverDispatch } from './SlideoverContext.js';

export default function Button(props = '') {

    const dispatch = useSlideoverDispatch();

    const item = JSON.parse(props.item);

    console.log(item);

    function handleClick(e) {

        switch (item.target) {

            case "slideover": {

                e.preventDefault();

                dispatch({type: 'opened', url: item.url})

                break;
            }
            default:
                
                location.href = item.url;

        }

    }

    return (

        <button
            onClick={(e) => handleClick(e)}
            className={ (item.color ? 'text-white bg-' + item.color + '-600 hover:bg-' + item.color + '-800' : 'text-neutral-600 bg-white hover:bg-'+ item.color +'-50') + " inline-flex leading-4 justify-center w-full rounded-md border border-ui-200 px-3 py-3 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ui-100 focus:ring-primary-500" }>
            { item.icon && <i className={(item.label ? 'mr-2 ' : '') + 'fa-light fa-fw ' + item.icon}></i> }
            { item.label } 
        </button>
    
    )

}