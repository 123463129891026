/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
import './../../bootstrap.js';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';
import { SlideoverProvider } from './components/SlideoverContext.js';
import { ComponentProvider } from './components/ComponentContext.js';

//const $ = require('jquery');

// create global $ and jQuery variables
//global.$ = global.jQuery = $;

document.addEventListener("DOMContentLoaded", function(e) {

    var root = document.getElementById('ui-root');

    if (root) {
        try {

            const app = createRoot(root);
            app.render( 

                    <SlideoverProvider>
                <ComponentProvider>
                        <App {...(root.dataset)} /> 
                </ComponentProvider>
                    </SlideoverProvider>
            );

        } catch (error) {
            console.error(error);
        }
    }

})
