
import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid';

import { useSlideover, useSlideoverDispatch } from './SlideoverContext.js';
import { ComponentDispatchContext } from './ComponentContext.js';


export default function Slideover() {

    const width     = 'w-1/2';

    const data      = useSlideover();
    const dispatch  = useSlideoverDispatch();
    const callback  = useContext(ComponentDispatchContext);

    function handler() {

        dispatch({type: 'closed'});

    }

    useEffect(() => {

    //    callback()

        
    }, [data])
 
    return ( 

        <Transition.Root show={data.isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={handler}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden w-1/3">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    afterEnter={callback}
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              {data.title}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handler}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {data.description}
                            </p>
                          </div>
                        </div>
                        <div className="relative flex-1 px-4 py-6 sm:px-6">
                            <div id='ui-slideover' className="absolute inset-0 py-6 px-4 sm:px-6">
                                <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                            </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        



    )

}