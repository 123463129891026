import React, { createContext, useContext, useCallback, useEffect, useReducer, useState } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import Dropdown from './Dropdown';
import Button 	from './Button';

export const ComponentDispatchContext = createContext(null);

function convertPlaceholders() {

	let result = [];
        	
	const placeholders 	= document.getElementsByClassName("ui-btnx");
	const drops 		= document.getElementsByClassName("ui-dropdown");

	const dropdowns = Array.from(drops, (el) => createPortal(
	    	<Dropdown {...(el.dataset)} />,
	    	el,
	    	uuidv4()
	    )  
	);

	result.push(...dropdowns);

	const buttons = Array.from(placeholders, (el) => createPortal(
			<Button {...(el.dataset)} />,
	    	el,
	    	uuidv4()
	    )  
	);
	

	result.push(...buttons);
    return result;


}

export function ComponentProvider({ children }) {

  	const [components, setComponents] = useState(convertPlaceholders());

  	function convertComponents() {

  		setComponents(convertPlaceholders())

  	}
    
    return (
        <ComponentDispatchContext.Provider value={convertComponents}>

            {children}
            {components}

        </ComponentDispatchContext.Provider>
    );
}

